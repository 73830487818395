/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { CollectionSlider, ProductSlider } from '../components/shared/sliders'
import {
  Productcard,
  Testimonial,
  HeroBlock,
  Decoration,
  Space,
  SEO,
} from '../components/shared'
import {
  mobileVw,
  desktopVw,
  fonts,
  colors,
  desktopBreakpoint,
} from '../styles'
import { ArrowDown } from '../components/shared/icons'
import { Menu } from '../components/boutiqueCollection'
import { useLocale, useDictionaryQuery } from '../hooks'

const EBoutiqueCollection = ({
  location,
  data: { contentfulPageEBoutiqueCollection, contentfulGlobalSite },
  pageContext: { shopenful },
}) => {
  const {
    slug,
    products,
    displayImages,
    displayAllProducts,
    bottomDecoration,
    bottomDecorationDesktop,
    heroblock,
    filteringOptions,
    descriptionBackground,
    seoDescription,
    seoTitle,
    imageSizeContain,
    filterSubTitle,
    productCollections,
    collectionSlider
  } = contentfulPageEBoutiqueCollection

  const {
    classicProductPageTestimonialDecoration,
    blackBrushesAllRecipePages,
  } = contentfulGlobalSite

  const { showMoreProducts, filterRemove, filterTitle } = useDictionaryQuery()

  const locale = useLocale();
  const collection = filteringOptions
const refContainer = useRef(null) 

  const matchingProducts = []

  const [collectionTags, setCollectionTags] = useState()

  const [menuMobile, setMobileMenu] = useState(false)

  const [showAll, setAll] = useState(false)
  const [checkboxes, UncheckAll] = useState(false)

  const [searchResults, updateSearchResults] = useState(null)

  const getSearchResults = (collectionTags) => {
    const newSearch = collectionTags
    UncheckAll(false)
    updateSearchResults(newSearch)
  }

  const handleEffacer = () => {
    UncheckAll(true)
    updateSearchResults(null)
  }

  const [menu, setMenu] = useState(0)
  
  const focusMenu = () => {
    {
      menu ? refContainer.current.focus() : null
    }
    viewDetails()
  }

  const viewDetails = () => {
    {
      menu
        ? window.scrollTo({
            top: refContainer.current.offsetTop - 100,
            behavior: 'smooth',
          })
        : null
    }
  }
  
  useEffect(() => {
    focusMenu()
  }, [menu])

  useEffect(() => {
    getSearchResults(collectionTags)
  }, [collectionTags])

  if (products && searchResults && searchResults.length > 0) {
    products.map(
      (product, i) =>
        product &&
        product.filterTags &&
        product.filterTags.map((tag) =>
          searchResults.indexOf(tag.value) !== -1
            ? matchingProducts.includes(product)
              ? null
              : matchingProducts.push(products[i])
            : null,
        ),
    )
  } else {
    products?.map((product, i) => matchingProducts.push(products[i]))
  }
  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://productreviews.shopifycdn.com//assets//v4//spr.js?shop=maille-fr.myshopify.com'
    script.async = true
    if (locale === 'fr') {
      setTimeout(() => document.body.appendChild(script), 1000)
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])
  return (
    <div>
      <HeroWrapper blockBreadcrumbs={heroblock.breadcrumbs.length > 2}>
        <SEO
          description={seoDescription}
          title={seoTitle}
          location={location}
          pageType='Collection e-boutique'
        />
        <HeroBlock
          data={{
            ...heroblock,
            marginDescription: ['72'],
            descriptionBackground,
            marginBottom: ['0', '0'],
          }}
          lines
          gold={!!slug.includes('les-classiques')}
          addSpace
          fontSize={60}
          marginTitle={[50]}
          imageSizeContain = {imageSizeContain}
        />
        {heroblock.background[0] !== 'white' && (
          <StyledDecoration>
            {blackBrushesAllRecipePages &&
              blackBrushesAllRecipePages.map((item, i) => {
                return <Decoration key={i} data={item} />
              })}
          </StyledDecoration>
        )}
      </HeroWrapper>
      {productCollections &&
            productCollections.map((collection, i) => (
                <CollectionSlider
                key={i}
                menu={menu}
                index={i}
                refContainer={refContainer}
                titleHeading={collection.title}
                newDescription={collection.description}
                slides={collection.slides}
                />
            ))}
      {
        displayImages &&
        <>
          <Space l={1} s={3} />
          <ImageWrapper>
            <ImageWrapperImage
              fluid={
                displayImages[0].fluid
                && displayImages[0].fluid
              }
              alt={
                displayImages[0] &&
                displayImages[0].title
              }
            />
            <ImageWrapperImage
              fluid={
                displayImages[1].fluid
                  ? displayImages[1].fluid
                  : displayImages[0].fluid
              }
              alt={
                displayImages[1]
                  ? displayImages[1].title
                  : displayImages[0].title
              }
            />
            <ImageWrapperImage
              fluid={
                displayImages[2].fluid
                  ? displayImages[2].fluid
                  : displayImages[0].fluid
              }
              alt={
                displayImages[2]
                  ? displayImages[2].title
                  : displayImages[0].title
              }
            />
          </ImageWrapper>
        </>
      }
      <ButtonWrapper>
        <Button onClick={() => setMobileMenu(!menuMobile)}>
          {!menuMobile ? 'filtrer' : 'fermer la sélection'}

          <StyledArrow />
        </Button>
      </ButtonWrapper>
      <MainSectionWrapper>
        {
          filteringOptions &&
          <FilterWrapper>
            <div
              style={{
                display: 'flex',
                marginBottom: `${desktopVw(50)}`,
                alignItems: 'center',
              }}
            >
              <Title>{filterTitle}</Title>
              <Span
                border={searchResults && searchResults.length > 0}
                onClick={() => handleEffacer()}
              >
                {filterRemove}
              </Span>
            </div>
            {filteringOptions ? (
              <Menu
                getTags={setCollectionTags}
                title={filterSubTitle}
                categories={collection}
                uncheckBoxes={checkboxes}
                openMobileMenu={menuMobile}
              />
            ) : null}
          </FilterWrapper>
        }
        <ResultsWrapper increaseHeight={filteringOptions && filteringOptions.length > 4} noPadding={filteringOptions}>
          <ProductGallery>
            {Array.from(new Set(matchingProducts.map(({ title }) => title)))
              .map((prodTitle) =>
                matchingProducts.find((prod) => prod.title === prodTitle),
              )
              .map((product, i) => {
                const productAvailable = (locale === 'fr' && product.shopifyProduct)
                  ? shopenful.find(
                    (prod) => prod.handle === product.shopifyProduct.handle,
                  )
                  : true
                return productAvailable ? (
                  (showAll || displayAllProducts) ? (
                    <ProductWrapper
                      key={i + product.title.replace(' ', '')}
                      classical={!!slug.includes('les-classiques')}
                      resize={product.resizeImage}
                      align={product.imageAlignment}
                    >
                      <Productcard
                        shopenful={shopenful}
                        product={{
                          ...product,
                          thumbnailImage: product.images[0],
                        }}
                        buttonText={
                          !!slug.includes('les-classiques') &&
                            product.isGourmetAndClassical === true
                            ? 'En savoir plus'
                            : null
                        }
                      />
                    </ProductWrapper>
                  ) : i <= 11 ? (
                    <ProductWrapper
                      key={i + product.title.replace(' ', '')}
                      classical={!!slug.includes('les-classiques')}
                      resize={product.resizeImage}
                      align={product.imageAlignment}
                    >
                      <Productcard
                        shopenful={shopenful}
                        product={{
                          ...product,
                          thumbnailImage: product.images[0],
                        }}
                        buttonText={
                          !!slug.includes('les-classiques') &&
                            product.isGourmetAndClassical === true
                            ? 'En savoir plus'
                            : null
                        }
                      />
                    </ProductWrapper>
                  ) : null
                ) : null
              })}
          </ProductGallery>
          <StyledButton
            disappear={matchingProducts.length <= 6 || displayAllProducts || showAll}
            onClick={() => setAll(!showAll)}
          >
            {showMoreProducts}
          </StyledButton>
        </ResultsWrapper>
      </MainSectionWrapper>
      {
        locale === 'fr' &&
        <div>
          <Testimonial
            decoration={classicProductPageTestimonialDecoration}
            goldBorder
          />
          <Space l={1} s={3} />
        </div>
      }
      <Space l={1} s={0} />
      {bottomDecoration && bottomDecorationDesktop ? (
        <div>
          <div className='mobileOnly'>
            <StyledFruitBanner
              fluid={bottomDecoration.image.fluid}
              alt={bottomDecoration.image.title}
            />
          </div>
          <div className='desktopOnly'>
            <StyledBanner
              fluid={bottomDecorationDesktop.image.fluid}
              alt={bottomDecorationDesktop.image.title}
            />
          </div>
        </div>
      ) : (
        <StyledFruitBanner
          fluid={
            bottomDecoration
              ? bottomDecoration.image.fluid
              : bottomDecorationDesktop.image.fluid
          }
          alt={
            bottomDecoration
              ? bottomDecoration.image.title
              : bottomDecorationDesktop.image.title
          }
        />
      )}
    </div>
  )
}

const StyledBanner = styled(Image)`
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 0 0;
  position: relative;

  img {
    image-rendering: pixelated;
    margin-bottom: 0;
  }
`

const HeroWrapper = styled.div`
  a + span {
    display: ${(props) => (props.blockBreadcrumbs ? `block` : null)};
  }

  p {
    margin-bottom: ${mobileVw(16)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    p {
      font-size: ${desktopVw(19)};
      margin-bottom: ${desktopVw(16)};
    }
  }
`

const MainSectionWrapper = styled.div`
  position: relative;
  z-index: 4;
  .label {
    display: block;
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${desktopVw(100)};
    .label {
      display: block;
      padding: ${desktopVw(5)} ${desktopVw(10)} ${desktopVw(3)};
      font-size: ${desktopVw(14)};
    }
  }
`

const StyledFruitBanner = styled(StyledBanner)`
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(740)};
  }
`

const Title = styled.h2`
  font-family: ${fonts.splandor};
  font-size: ${desktopVw(30)};
  margin-right: ${desktopVw(120)};
`

const Span = styled.span`
  font-family: ${fonts.tivoli};
  font-size: ${desktopVw(16)};
  text-transform: uppercase;
  border-bottom: ${(props) =>
    props.border ? `solid ${desktopVw(1)} ${colors.black}` : 'none'};
  cursor: pointer;
`

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Title}, ${Span} {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    padding-left: ${desktopVw(128)};

    ${Title}, ${Span} {
      display: block;
    }
  }
`

const ResultsWrapper = styled.div`
  padding: 0 ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {

    padding: ${(props) =>
    props.noPadding ? `0 ${desktopVw(129)} 0 ${desktopVw(413)}` : `0 auto 0`
  } ;
        }
  }
`

const ProductGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: ${desktopBreakpoint}) {
    justify-content: flex-start;
  }
`

const ButtonWrapper = styled.div`
  padding: 0 ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const StyledButton = styled.button`
  width: ${mobileVw(288)};
  background-color: ${colors.mailleGold};
  padding: ${mobileVw(15)} 0 ${mobileVw(14)};
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(16)};
  color: ${colors.white};
  text-transform: uppercase;
  letter-spacing: ${mobileVw(2)};
  display: block;
  margin: 0 auto;
  z-index: 1;
  display: ${(props) => (props.disappear ? `none` : `block`)};
  cursor: pointer;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(350)};
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: ${desktopVw(16)};
    margin: ${desktopVw(25)} auto ${desktopVw(40)};
  }
`

const Button = styled(StyledButton)`
  width: 100%;
  background-color: ${colors.lightGrey};
  font-size: ${mobileVw(14)};
  line-height: ${mobileVw(14)};
  letter-spacing: ${mobileVw(1.4)};
  color: ${colors.black};
  padding: ${mobileVw(23)} 0 ${mobileVw(22)};
  margin: 0;

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const StyledArrow = styled(ArrowDown)`
  width: ${mobileVw(8)};
  height: auto;
  stroke: ${colors.black};
  margin-left: ${mobileVw(5)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(8)};
    margin-left: ${desktopVw(15)};
    margin-bottom: ${desktopVw(2)};
  }
`

const ProductWrapper = styled.div`
  margin-bottom: ${mobileVw(8)};
  width: ${mobileVw(136)};

  p {
    margin-bottom: 0;
  }

  .price {
    display: ${(props) => (props.classical ? `none` : `flex`)};
  }

  .rating {
    svg {
      fill: ${(props) => (props.classical ? `${colors.lightGrey}` : null)};
    }
    padding-bottom: ${(props) => (props.classical ? `${mobileVw(10)}` : `0`)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(255)};
    margin-left: ${desktopVw(19)};
    .rating {
      padding-bottom: ${(props) =>
    props.classical ? `${desktopVw(10)}` : `0`};
    }
  }
`
const ImageWrapper = styled.div`
margin: 0 10%;

@media (min-width: ${desktopBreakpoint}){
  display:flex;
}

`

const ImageWrapperImage = styled(Image)`
@media (min-width: ${desktopBreakpoint}){
  width: ${desktopVw(500)};
  z-index: 2;
}  
`

const StyledDecoration = styled.div`
  position: relative;
  background: #000;
  margin-bottom: ${desktopVw(50)};
  padding-top: ${mobileVw(40)};
  margin-bottom: ${mobileVw(15)};

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${desktopVw(100)};
    margin-bottom: ${desktopVw(50)};
  }
`

export const boutiqueCollection = graphql`
  query EBoutiqueCollection($id: String!, $node_locale: String!) {
    contentfulPageEBoutiqueCollection(id: { eq: $id }) {
      seoDescription
      seoTitle
      title
      slug
      description {
        description
      }
      thumbnailImage {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      displayAllProducts
      products {
        thumbnailLabel
        promotion
        isGourmetAndClassical
        shopifyProduct {
          id
          handle
          variants {
            edges {
              node {
                id
                title
                price{
                    amount
                }
              }
            }
          }
        }
        title
        slug
        productTags
        resizeImage
        imageAlignment
        filterTags {
          value
        }
        images {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
          title
        }
        thumbnailImage {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
          title
        }
      }
      productCollections {
        description {
            raw
          }
        slides {
          ... on ContentfulPageEBoutiqueCollection {
            id
            name
            lifestyleImage {
                fluid(maxWidth: 1800) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
                title
            }
            slug
          }
        }
      }
      displayImages{
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
          title
      }
      bottomDecoration {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      bottomDecorationDesktop {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      filterSubTitle: filterTitle
      filteringOptions {
        name
        title
        value
        subcategory {
          name
          title
          value
        }
      }
      descriptionBackground {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      imageSizeContain
      heroblock {
        title
        marginDescription
        marginBottom

        background
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        textSlider {
          title
          text {
            text
          }
          description {
            raw
          }
        }
        richDescription {
          raw
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        video {
          name
          poster {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          videoMov {
            file {
              url
            }
          }
          videoMp4 {
            file {
              url
            }
          }
        }
        decoration {
          image {
            fluid(maxWidth: 2400) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
        cta{
          title
          link {
            ... on ContentfulPageLanding {
              slug
            }
          }
        }
      }
    }
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      blackBrushesAllRecipePages {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      defaultTestimonialImage {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      classicProductPageTestimonialDecoration {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      defaultVideoTestimonial {
        name
        poster {
          fluid {
            src
          }
        }
        videoMov {
          file {
            url
          }
        }
        videoMp4 {
          file {
            url
          }
        }
      }
    }
  }
`

export default EBoutiqueCollection
